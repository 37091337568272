import { ref } from '@vue/composition-api';
import store from '@/store';
import contractStoreModule from '../contractStoreModule';

export default function useContractPreviewModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'contract';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, contractStoreModule);
  }

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const pdfUrl = ref(null);
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const fetchPdfPreviewUrl = () => {
    isLoading.value = true;
    resetModal();
    if (!itemLocal || !itemLocal.value || !itemLocal.value.id) {
      pdfUrl.value = null;
      isLoading.value = false;
      return;
    }
    if (itemLocal.value.previewUrl) {
      pdfUrl.value = itemLocal.value.previewUrl;
      isLoading.value = false;
      return;
    }
    store.dispatch('contract/previewContract', { id: itemLocal.value.id, type: 1 }).then(response => {
      pdfUrl.value = response.data;
      isLoading.value = false;
    });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    pdfUrl,
    isLoading,
    fetchPdfPreviewUrl,
    resetItemLocal,
    resetModal,
  };
}
