<template>
  <vue-good-table
    class="mt-2"
    :columns="columns"
    :rows="assets"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
  >
    <template slot="loadingContent">
      <b-spinner
        label="Loading"
        type="grow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: isRepresent -->

      <!-- Column: Image -->
      <span v-if="props.column.field === 'note'">
        <b-form-input
          :id="`note-${props.row.originalIndex}`"
          v-model="props.row.note"
          placeholder=""
          trim
          @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
        />

      </span>
      <span v-else-if="props.column.field === 'status'">
        <b-form-input
          :id="`status-${props.row.originalIndex}`"
          v-model="props.row.status"
          placeholder=""
          trim
          @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
        />

      </span>

      <!-- Column: Image -->
      <span v-else-if="props.column.field === 'penaltyFee'">
        <number-input
          v-model="props.row.penaltyFee"
          :default-value="props.row.penaltyFee"
          @input="changeCell(props.row['penaltyFee'], 'penaltyFee', props.row.originalIndex)"
        />

      </span>

      <span v-else-if="props.column.field === 'name'">
        <div> {{ props.formattedRow[props.column.field] }}</div>
        <small class="text-muted">Tình trạng ban đầu: {{ props.row.oldData.status }}</small>
        <small
          v-if="props.row.oldData.note"
          class="text-muted"
        ><br>Ghi chú: {{ props.row.oldData.note }}</small>

      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <div slot="emptystate">
      <div class="text-center text-muted">
        Không có bản ghi nào!
      </div>
    </div>
  </vue-good-table>
</template>

<script>
import {
  BSpinner,
  BFormInput,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import useJwt from '@/auth/jwt/useJwt';
import NumberInput from '@/views/components/NumberInput.vue';

export default {
  components: {
    BSpinner,
    BFormInput,
    VueGoodTable,
    NumberInput,
  },
  props: {
    contractId: {
      type: Number,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      assets: this.data,
    };
  },

  created() {
    if (this.data.length === 0) {
      this.fetchData();
    }
  },
  methods: {
    changeCell(changedData, column, row) {
      this.assets[row][column] = changedData;
      this.onChange();
    },
    onChange() {
      this.$emit('input', this.assets);
    },
    fetchData() {
      if (this.contractId && this.contractId > 0) {
        useJwt.getOtherHandoverTingTongByContract({ contractId: this.contractId, isLiquid: false }).then(response => {
          this.assets = response.data.map(object => ({
            name: object.name,
            status: '',
            penaltyFee: 0,
            note: '',
            oldData: object,
          }));
          this.onChange();
        });
      } else {
        useJwt.getOtherHandoverTingTong().then(response => {
          this.assets = response.data;
          this.onChange();
        });
      }
    },
  },
  setup() {
    // Table Handlers
    const rows = [];
    const columns = [
      {
        label: 'Danh mục',
        field: 'name',
        sortable: false,
      },
      {
        label: 'Tình trạng',
        field: 'status',
        sortable: true,
        width: '200px',
      },
      {
        label: 'Chi phí phạt',
        field: 'penaltyFee',
        sortable: false,
        width: '150px',
      },
      {
        label: 'Ghi chú',
        field: 'note',
        sortable: false,
        width: '200px',
      },
    ];

    return {
      rows,
      columns,
    };
  },
};
</script>
