<template>
  <div class="contract-list-container">
    <b-row
      v-if="analytic"
      class="match-height"
    >

      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          title="Hiệu lực"
          :value="analytic.active"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>

      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          title="Sắp hết hạn"
          :value="analytic.expiring"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          title="Quá hạn"
          :value="analytic.expired"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="3"
        lg="3"
      >
        <card-statistic
          title="Đã thanh lý"
          :value="analytic.liquid"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Hợp đồng</b-card-title>
        <crud-buttons
          name="Hợp đồng"
          :selected-rows="selectedRows"
          modal="modal-contract"
          :show-import="$can('create', 'contract')"
          :show-export="$can('read', 'contract')"
          :hide-delete="!$can('delete', 'contract')"
          :enable-add="$can('create', 'contract')"
          :show-apartment="true"
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
          @on-import-file="importData"
          @download-template-file="downloadImportTemplate"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <select-apartment v-model="apartment" />
          </b-col>
          <b-col><select-room
            v-model="room"
            :apartment="apartment"
          /></b-col>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"
          >
            <select-bed
              v-model="bed"
              :room="room"
            />
          </b-col>
          <b-col>
            <select-contract-status
              v-model="status"
              :default-value="status"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'contractNumber'">
                  <b-link
                    v-b-modal.modal-contract-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.contractNumber }}
                  </b-link>
                </span>

                <!-- Column: Index -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.name }}</span>
                  <span
                    v-if="props.row.apartment"
                    class="text-muted"
                  ><br>Tòa nhà: {{ props.row.apartment.name }}</span>
                  <span
                    v-if="props.row.room"
                    class="text-muted"
                  ><br>Phòng: {{ props.row.room.name }}</span>
                  <span
                    v-if="props.row.bed"
                    class="text-muted"
                  ><br>Giường: {{ props.row.bed.name }}</span>
                </span>

                <!-- Column: Index -->
                <span v-else-if="props.column.field === 'index'">
                  {{ props.row.originalIndex + 1 }}
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'price'">
                  {{ Number(props.row.price).toLocaleString() }} đ
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'deposit'">
                  {{ Number(props.row.deposit).toLocaleString() }} đ
                </span>
                <span v-else-if="props.column.field === 'creator'">
                  {{ props.row.creator && props.row.creator.user ? props.row.creator.user.name : props.row.owner.name }}
                </span>
                <!-- Column: Price -->
                <span v-else-if="['startDate', 'endDate'].includes(props.column.field)">
                  {{ parseDateToString(props.row[props.column.field]) }}
                </span>

                <!-- Column: Sign Status -->
                <span v-else-if="props.column.field === 'status' && props.row.statusObject">
                  <b-badge
                    pill
                    :variant="props.row.statusObject.variant"
                    class="font-small-1"
                  >
                    {{ props.row.statusObject.name }}
                  </b-badge>
                  <b-badge
                    v-if="parseInt(props.row.statusObject.id, 10) === 1"
                    pill
                    :variant="getRemainDaysVariant(props.row.remainDays).variant"
                    class="font-small-1 ml-50"
                  >
                    {{ getRemainDaysVariant(props.row.remainDays).text }}
                  </b-badge>
                  <b-badge
                    v-if="props.row.leavingDate && parseInt(props.row.statusObject.id, 10) === 1"
                    pill
                    variant="light-warning"
                    class="font-small-1 ml-50"
                  >
                    Sắp chuyển đi
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="Hợp đồng"
                    modal="modal-contract"
                    :disable-edit="parseInt(props.row.status, 10) === 2"
                    :hide-delete="!$can('delete', 'contract')"
                    :hide-edit="!$can('update', 'contract')"
                    @on-delete="onDelete(props.row)"
                    @on-edit="onEditItem(props.row)"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button
                        v-if="$can('extend', 'contract')"
                        v-b-tooltip.hover.v-success
                        v-b-modal.modal-contract-update-time
                        :disabled="parseInt(props.row.status, 10) === 2"
                        variant="success"
                        class="btn-icon mr-50"
                        size="sm"
                        title="Gia hạn hợp đồng"
                        @click="onExtendContract(props.row)"
                      >
                        <feather-icon icon="CalendarIcon" />
                      </b-button>
                      <b-button
                        v-if="$can('move', 'contract')"
                        v-b-tooltip.hover.v-info
                        v-b-modal.modal-move-contract
                        :disabled="parseInt(props.row.status, 10) === 2"
                        variant="info"
                        class="btn-icon mr-50"
                        size="sm"
                        title="Chuyển phòng/giường"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="TruckIcon" />
                      </b-button>
                      <b-button
                        v-if="$can('leave', 'contract')"
                        v-b-tooltip.hover.v-primary
                        v-b-modal.modal-contract-update-time
                        :disabled="parseInt(props.row.status, 10) === 2"
                        variant="primary"
                        class="btn-icon mr-50"
                        size="sm"
                        title="Đăng ký ngày chuyển đi"
                        @click="onUpdateLeavingContract(props.row)"
                      >
                        <feather-icon icon="ClockIcon" />
                      </b-button>
                      <b-button
                        v-if="$can('transfer', 'contract')"
                        v-b-tooltip.hover.v-success
                        v-b-modal.modal-transfer-contract
                        :disabled="parseInt(props.row.status, 10) === 2"
                        variant="success"
                        class="btn-icon mr-50"
                        size="sm"
                        title="Nhượng hợp đồng"
                        @click="onUpdateLeavingContract(props.row)"
                      >
                        <feather-icon icon="TriangleIcon" />
                      </b-button>
                      <b-button
                        v-if="$can('liquid', 'contract') && parseInt(props.row.status, 10) === 1"
                        v-b-tooltip.hover.v-warning
                        v-b-modal.modal-contract-liquidation
                        variant="warning"
                        class="btn-icon mr-50"
                        size="sm"
                        title="Thanh lý hợp đồng"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="DeleteIcon" />
                      </b-button>
                      <b-button
                        v-if="$can('recover', 'contract') && parseInt(props.row.status, 10) === 2"
                        v-b-tooltip.hover.v-warning
                        variant="warning"
                        class="btn-icon mr-50"
                        size="sm"
                        title="Khôi phục hợp đồng"
                        @click="onRecoverButtonClick(props.row)"
                      >
                        <feather-icon icon="DeleteIcon" />
                      </b-button>

                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <contract-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <contract-liquidation
      :item="item"
      @refetch-data="fetchData"
    />
    <contract-update-time-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <contract-detail-modal :contract-id="item.id" />
    <move-contract-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <transfer-contract
      :item="item"
      @refetch-data="fetchData"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  VBTooltip,
  BFormSelect,
  BSpinner,
  BBadge,
  BButton,
  VBModal,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import SelectContractStatus from '@/views/components/SelectContractStatus.vue';
import TableActions from '@/views/components/TableActions.vue';
import { VueGoodTable } from 'vue-good-table';
import CrudButtons from '@/views/components/CrudButtons.vue';
import CardStatistic from '@/views/components/CardStatistic.vue';
import { parseDateToString } from '@/auth/utils';
import useContractList from './useContractList';
import ContractModal from '../modal/ContractModal.vue';
import ContractLiquidation from '../liquidation/ContractLiquidation.vue';
import ContractUpdateTimeModal from '../time-modal/ContractUpdateTimeModal.vue';
import ContractDetailModal from '../detail/ContractDetailModal.vue';
import MoveContractModal from '../move/MoveContractModal.vue';
import TransferContract from '../transfer/TransferContract.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BButton,
    TableActions,
    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectContractStatus,
    VueGoodTable,
    CrudButtons,
    ContractModal,
    CardStatistic,
    ContractLiquidation,
    ContractUpdateTimeModal,
    ContractDetailModal,
    MoveContractModal,
    TransferContract,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const {
      analytic,
      item,
      columns,
      rows,
      apartment,
      room,
      bed,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchContracts,
      deleteContracts,
      resetItem,
      onEditItem,
      onExtendContract,
      onUpdateLeavingContract,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      getRemainDaysVariant,
      downloadImportTemplate,
      importData,
      exportData,
      resolveColWidthIfDisableBed,
      recoverContract,
    } = useContractList();

    return {
      analytic,
      item,
      columns,
      rows,
      apartment,
      room,
      bed,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchContracts,
      deleteContracts,
      resetItem,
      onEditItem,
      onExtendContract,
      onUpdateLeavingContract,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      getRemainDaysVariant,
      downloadImportTemplate,
      importData,
      exportData,
      parseDateToString,
      resolveColWidthIfDisableBed,
      recoverContract,
    };
  },

  data() {
    return {
      isEdit: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onDelete(contract) {
      const deleteObjects = contract && contract.id > 0 ? [contract] : this.selectedRows;
      this.deleteContracts(deleteObjects);
    },
    resetIsEditValue() {
      this.isEdit = false;
    },
    onRecoverButtonClick(contract) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác khôi phục hợp đồng ${contract.name.toLowerCase()}. Bạn có chắc chắn muốn khôi phục hợp đồng này không?`,
          {
            title: `Khôi phục hợp đồng ${contract.name.toLowerCase()}`,
            okTitle: 'Khôi phục ngay',
            cancelTitle: 'Huỷ',
            okVariant: 'warning',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.recoverContract(contract.id);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.contract-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
              min-width: 160px !important;
              width: 160px !important;
            }

            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(6) {
               min-width: 130px !important;
            }
            &:nth-child(7) {
               min-width: 130px !important;
            }
          }
        }
      }
    }
}
</style>
