<template>
  <b-modal
    id="modal-move-contract"
    ref="refModalMoveContract"
    title="Chuyển phòng/giường"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="itemLocal"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-alert
        show
        variant="primary"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span
            v-if="itemLocal.apartment && itemLocal.room && itemLocal.bed"
            class="ml-25"
          >Khách hàng đang ở tại: <strong>{{ itemLocal.apartment.name }}</strong> phòng <strong>{{ itemLocal.room.name }} giường <strong>{{ itemLocal.bed.name }} </strong></strong></span>
          <span
            v-else-if="itemLocal.apartment && itemLocal.room"
            class="ml-25"
          >Khách hàng đang ở tại: <strong>{{ itemLocal.apartment.name }}</strong> phòng <strong>{{ itemLocal.room.name }}</strong></span>
        </div>
      </b-alert>
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                Thông tin chung
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col :md="resolveColWidthIfDisableBed()">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules="required"
            >
              <select-apartment
                v-model="submitData.apartment"
                required
                :filter-active="true"
                :default-value="submitData.apartment"
                label="Tòa nhà"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col :md="resolveColWidthIfDisableBed()">
            <validation-provider
              #default="validationContext"
              name="room"
              rules="required"
            >
              <select-room
                v-model="submitData.room"
                :filter-active="true"
                required
                :apartment="submitData.apartment"
                :default-value="submitData.room"
                label="Phòng"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :show-status="true"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"
            :md="resolveColWidthIfDisableBed()"
          >
            <validation-provider
              #default="validationContext"
              name="Giường"
              :rules="submitData.room && submitData.room.numberActiveBeds > 0 ? 'required' : ''"
            >
              <select-bed
                v-model="submitData.bed"
                :room="submitData.room"
                :filter-active="true"
                :default-value="submitData.bed"
                label="Giường"
                :enable="submitData.room && submitData.room.numberActiveBeds > 0"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col v-if="isTingTong">
            <validation-provider
              #default="validationContext"
              name="Tiền phí chuyển phòng"
              rules="required"
            >
              <number-input
                v-model="submitData.moveFee"
                required
                label="Tiền phí chuyển phòng"
                :default-value="submitData.moveFee"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Tiền thuê"
              rules="required"
            >
              <number-input
                v-if="!isTingTong"
                v-model="submitData.price"
                required
                label="Tiền thuê"
                :default-value="submitData.price"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
              <b-form-group
                v-else
                label="Tiền thuê"
              >
                <b-form-input
                  :value="Number(submitData.price).toLocaleString()"
                  readonly
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="Tiền cọc"
              rules="required"
            >
              <select-deposit-month
                v-if="isTingTong"
                v-model="submitData.depositMonthObject"
                :default-value="itemLocal.depositMonthObject"
                label="Tiền cọc"
                required
              />
              <number-input
                v-else
                v-model="submitData.deposit"
                required
                label="Tiền cọc"
                :default-value="submitData.deposit"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            cols="4"
          >
            <validation-provider

              #default="validationContext"
              name="moveDate"
              rules="required"
            >
              <select-date
                v-model="submitData.moveDate"
                required
                :default-value="submitData.moveDate"
                label="Ngày chuyển"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>

          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                Bàn giao tài sản phòng cũ
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="isTingTong">
          <b-col cols="12">
            <handover-liquid-table
              v-model="submitData.liquidHandover"
              :apartment="itemLocal.apartment"
              :room="itemLocal.room"
              :contract-id="itemLocal.id"
            />
          </b-col>
          <b-col cols="12">
            <other-handover-liquid-table
              v-model="submitData.liquidOtherHandover"
              :contract-id="itemLocal.id"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                Dịch vụ phòng mới
              </div>
              <b-button
                v-b-modal.modal-list-service
                variant="primary"
                class="btn-icon ml-auto"
                size="sm"
              >
                <feather-icon icon="PlusSquareIcon" />
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">

            <fee-table
              :id="itemLocal.id"
              v-model="submitData.contractFees"
              :rows="submitData.contractFees"
              :apartment="submitData.apartment"
              :room="submitData.room"
            />

          </b-col>
        </b-row>

        <b-row v-if="isTingTong && submitData.apartment && submitData.room">
          <b-col
            cols="12"
            class="mt-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                Bàn giao tài sản phòng mới
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <handover-table
              v-model="submitData.handover"
              :apartment="submitData.apartment"
              :room="submitData.room"
            />
          </b-col>
          <b-col
            v-if="isTingTong"
            cols="12"
          >
            <other-handover-ting-tong-table
              v-model="submitData.otherHandover"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <list-service-modal
      :item-local="itemLocal"
      :fees-selected="itemLocal.fees"
      :apartment="submitData.apartment"
      @selectService="onAddService"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { parseDateToString } from '@/auth/utils';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDepositMonth from '@/views/components/SelectDepositMonth.vue';
import useMoveContractModal from './useMoveContractModal';
import FeeTable from '../modal/FeeTable.vue';
import ListServiceModal from '../listServiceModal/ListServiceModal.vue';
import HandoverLiquidTable from '../liquidation/HandoverLiquidTable.vue';
import HandoverTable from '../modal/HandoverTable.vue';
import OtherHandoverLiquidTable from '../liquidation/OtherHandoverLiquidTable.vue';
import OtherHandoverTingTongTable from '../modal/OtherHandoverTingTongTable.vue';

export default {
  components: {
    BModal,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationObserver,
    ValidationProvider,

    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectDate,
    NumberInput,
    SelectDepositMonth,
    FeeTable,
    ListServiceModal,
    BButton,
    HandoverLiquidTable,
    HandoverTable,
    OtherHandoverTingTongTable,
    OtherHandoverLiquidTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'extend',
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalMoveContract,
      itemLocal,
      submitData,
      isSubmitting,
      isTingTong,
      resetItemLocal,
      resetModal,
      resolveColWidthIfDisableBed,
      onSubmit,
      onAddService,
    } = useMoveContractModal(props, emit, refFormObserver);

    return {
      refModalMoveContract,
      itemLocal,
      submitData,
      isSubmitting,
      isTingTong,
      resetItemLocal,
      resetModal,
      onSubmit,
      resolveColWidthIfDisableBed,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      parseDateToString,
      onAddService,
    };
  },
};
</script>
