import {
  ref,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableContractAssetCheck, parseDateToString } from '@/auth/utils';
import moment from 'moment';
import Vue from 'vue';
import contractStoreModule from '../contractStoreModule';

export default function useTransferContract(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'contract';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, contractStoreModule);
  }

  const toastification = toast();
  const refModalTransferContract = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref({
    ...JSON.parse(JSON.stringify(props.item)),
    transferDate: moment().toDate(),
    transferEndDate: props.item.endDate,
    transferNote: '',
    transferTenants: [],
    handover: [],
    otherHandover: [],
    newContractFees: props.item.contractFees,
  });
  const resetItemLocal = () => {
    itemLocal.value = {
      ...JSON.parse(JSON.stringify(props.item)),
      transferDate: moment().toDate(),
      transferEndDate: props.item.endDate,
      transferNote: '',
      transferTenants: [],
      handover: [],
      otherHandover: [],
      newContractFees: props.item.contractFees,
    };
  };
  const isSubmitting = ref(false);
  const resetModal = () => {
    resetItemLocal();
    isSubmitting.value = false;
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      contractId: itemLocal.value.id,
      transferDate: parseDateToString(itemLocal.value.transferDate),
      transferEndDate: parseDateToString(itemLocal.value.transferEndDate),
      transferNote: itemLocal.value.transferNote,
    };
    data.tenants = itemLocal.value.transferTenants.map(contractTenant => ({ id: contractTenant.tenant.id, isRepresent: contractTenant.isRepresent }));
    // list service
    data.fees = itemLocal.value.newContractFees.map(contractFee => {
      const feeItem = {
        id: contractFee.fee.id,
        coefficient: contractFee.coefficient,
        quantity: contractFee.quantity,
        firstIndex: contractFee.firstIndex,
      };
      feeItem.billingDate = parseDateToString(contractFee.billingDate);

      if (contractFee.meter && contractFee.meter.id > 0) {
        feeItem.meterId = contractFee.meter.id;
      }
      return feeItem;
    });
    // handover
    if (itemLocal.value.handover && itemLocal.value.handover.length > 0) {
      data.handover = itemLocal.value.handover.map(object => ({
        assetId: object.asset.id,
        isLessorResponsibility: object.isLessorResponsibility,
        note: object.note,
        base64String: object.base64String,
      }));
    }
    if (itemLocal.value.otherHandover && itemLocal.value.otherHandover.length > 0) {
      data.otherHandover = itemLocal.value.otherHandover.map(object => ({
        oldDataId: object.oldData.id,
        note: object.note,
        name: object.name,
        status: object.status,
        penaltyFee: object.penaltyFee,
      }));
    }
    const dispatchName = "contract/transferContract";

    isSubmitting.value = true;
    store
      .dispatch(dispatchName, data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalTransferContract.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  const onAddTenant = listTenantSelected => {
    const listTenant = listTenantSelected.map(({ id, phone, name }) => ({
      tenant: {
        id,
        name,
        phone,
      },
      isRepresent: !!itemLocal.value.bed,
    }));
    const arrMixUpTenants = [...itemLocal.value.transferTenants, ...listTenant];
    const checkHasRepresent = arrMixUpTenants.filter(tenant => !!tenant.isRepresent);
    if (!checkHasRepresent.length && arrMixUpTenants.length) arrMixUpTenants[0].isRepresent = true;
    itemLocal.value.transferTenants = arrMixUpTenants;
    Vue.set(itemLocal.value, "transferTenants", arrMixUpTenants);
  };

  const onAddService = listServiceSelected => {
    const listService = listServiceSelected.map(obj => ({
      fee: obj,
      quantity: 1,
      coefficient: 1,
      firstIndex: 0,
      billingDate: itemLocal.value.billingDate,
      lastBillingDate: itemLocal.value.billingDate,
      meter: null,
    }));
    const arrMixUpServices = [...itemLocal.value.newContractFees, ...listService];
    itemLocal.value.newContractFees = arrMixUpServices;
  };

  return {
    refModalTransferContract,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    onAddTenant,
    isEnableContractAssetCheck,
    onAddService,
  };
}
