import { ref } from '@vue/composition-api';
import store from '@/store';

export default function usePdfPreviewModal(props, emit, refFormObserver) {
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const previewType = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    previewType.value = props.previewType;
  };
  const pdfUrl = ref(null);
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const fetchPdfPreviewUrl = () => {
    isLoading.value = true;
    resetModal();
    if (!itemLocal || !itemLocal.value || !itemLocal.value.id) {
      pdfUrl.value = null;
      isLoading.value = false;
      return;
    }
    // if (itemLocal.value.previewUrl) {
    //   pdfUrl.value = itemLocal.value.previewUrl;
    //   isLoading.value = false;
    //   return;
    // }
    store.dispatch('contract/previewContract', { id: itemLocal.value.id, type: previewType.value }).then(response => {
      pdfUrl.value = response.data;
      isLoading.value = false;
    });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    pdfUrl,
    isLoading,
    fetchPdfPreviewUrl,
    resetItemLocal,
    resetModal,
  };
}
