<template>
  <b-modal
    id="modal-contract-detail"
    ref="refModalContractDetail"
    title="Thông tin hợp đồng"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-button
        v-if="!isTingTong && !applyNewContractTemplate"
        v-b-modal.modal-html-contract
        size="sm"
      >
        Xem điều khoản
      </b-button>
      <b-button
        v-if="!isTingTong && !applyNewContractTemplate"
        size="sm"
        class="ml-1"
        variant="primary"
        @click="downloadContractPdf"
      >
        Tải xuống (PDF)
      </b-button>
      <b-button
        v-if="applyNewContractTemplate"
        size="sm"
        variant="primary"
        @click="downloadContractDocuments"
      >
        Tải hợp đồng (Word)
      </b-button>
      <b-button
        size="sm"
        class="ml-1"
        variant="success"
        @click="sendContractEmail"
      >
        Gửi email
      </b-button>
      <b-button
        v-if="itemLocal.signStatus.id !== '3' && enableSignContract && itemLocal.status != 2"
        size="sm"
        class="ml-1"
        variant="info"
        @click="sendContractSigningEmail"
      >
        Gửi email ký hợp đồng
      </b-button>
      <b-button
        v-if="itemLocal.signStatus.id !== '3' && enableSignContract && itemLocal.status != 2"
        v-clipboard:copy="copySigningUrl()"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        size="sm"
        class="ml-1"
        variant="danger"
      >
        Sao chép link ký HĐ
      </b-button>
      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1 pt-2"
      >
        1. Thông tin chung
      </div>
      <b-list-group class="pb-2">

        <b-list-group-item v-if="itemLocal.apartment">
          <b-row>
            <b-col class="font-weight-bolder">
              Tên tòa nhà:
            </b-col>
            <b-col class="">
              {{ itemLocal.apartment.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.room">
          <b-row>
            <b-col class="font-weight-bolder">
              Tên phòng:
            </b-col>
            <b-col class="">
              {{ itemLocal.room.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.bed">
          <b-row>
            <b-col class="font-weight-bolder">
              Tên giường:
            </b-col>
            <b-col class="">
              {{ itemLocal.bed.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.tenant">
          <b-row>
            <b-col class="font-weight-bolder">
              Khách cọc:
            </b-col>
            <b-col class="">
              {{ itemLocal.tenant.name }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Giá thuê:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.price).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Đặt cọc:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.deposit).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.promotionMonth > 0 && itemLocal.promotionPricePerMonth > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              Khuyến mại:
            </b-col>
            <b-col class="">
              {{ itemLocal.promotionMonth }} tháng, mỗi tháng {{ Number(itemLocal.promotionPricePerMonth).toLocaleString() }} đ
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.paymentPeriod">
          <b-row>
            <b-col class="font-weight-bolder">
              Chu kỳ thanh toán:
            </b-col>
            <b-col class="">
              {{ itemLocal.paymentPeriod.title }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày bắt đầu tính tiền:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.billingDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày ký:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.signDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày bắt đầu:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.startDate ) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày kết thúc:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.endDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.leavingDate">
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày đăng ký chuyển:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.leavingDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.note">
          <b-row>
            <b-col class="font-weight-bolder">
              Ghi chú:
            </b-col>
            <b-col class="">
              {{ itemLocal.note }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.statusObject">
          <b-row>
            <b-col class="font-weight-bolder">
              Trạng thái:
            </b-col>
            <b-col class="">
              <b-badge
                pill
                :variant="itemLocal.statusObject.variant"
              >{{ itemLocal.statusObject.name }}</b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="applyNewContractTemplate">
          <b-row>
            <b-col class="font-weight-bolder">
              Giấy tờ ký HĐ:
            </b-col>
            <b-col class="">
              <div>
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(1)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">1. Hợp đồng thuê</span>
                </b-link>
              </div>
              <div
                v-if="!itemLocal.targetContractHistory"
                class="mt-1"
              >
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(2)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">2. BBBG tài sản</span>
                </b-link>
              </div>
              <div
                v-if="itemLocal.targetContractHistory"
                class="mt-1"
              >
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(5)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">2. Biên bản nhận nhượng</span>
                </b-link>
              </div>

            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="parseInt(itemLocal.status, 10) === 2 && applyNewContractTemplate">
          <b-row>
            <b-col class="font-weight-bolder">
              Giấy tờ thanh lý HĐ:
            </b-col>
            <b-col class="">
              <div>
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(3)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">1. BB thanh lý hợp đồng</span>
                </b-link>
              </div>
              <div
                v-if="!itemLocal.sourceContractHistory"
                class="mt-1"
              >
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(4)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">2. BB bàn giao</span>
                </b-link>
              </div>
              <div
                v-if="itemLocal.sourceContractHistory"
                class="mt-1"
              >
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(5)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">2. Biên bản nhượng</span>
                </b-link>
              </div>

            </b-col>

          </b-row>
        </b-list-group-item>

      </b-list-group>

      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary"
      >
        2. Danh sách khách hàng
      </div>
      <tenant-table :rows="itemLocal.tenants" />
      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-2"
      >
        3. Dịch vụ sử dụng
      </div>
      <fee-table :rows="itemLocal.contractFees" />
      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-2"
      >
        4. Đính kèm
      </div>
      <media-view
        v-model="itemLocal.attachments"
        :label="null"
        :initial-value="itemLocal.attachments"
        :upload-imediately="true"
        :target-object="{id: itemLocal.id, type: 'contract'}"
        :preview-mode="!$can('update', 'contract')"
        @on-delete-uploaded-file="onDeleteAttachment"
      />
      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-2"
      >
        5. Lịch sử gia hạn
      </div>
      <extend-history-table :rows="itemLocal.extendHistories" />

      <contract-html-modal :contract-id="itemLocal.id" />
      <contract-preview-modal :item="itemLocal" />
      <pdf-preview-modal
        :item="itemLocal"
        title="Biên bản bàn giao tài sản"
        :preview-type="previewType"
      />

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BLink,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import TenantTable from '@/views/property/room/detail/TenantTable.vue';
import { parseDateToString } from '@/auth/utils';
import MediaView from '@/views/components/media/MediaView.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import PdfPreviewModal from '@/views/components/pdf/PdfPreviewModal.vue';
import useContractDetailModal from './useContractDetailModal';
import FeeTable from './FeeTable.vue';
import ContractHtmlModal from '../html/ContractHTMLModal.vue';
import ExtendHistoryTable from './ExtendHistoryTable.vue';
import ContractPreviewModal from '../preview/ContractPreviewModal.vue';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    TenantTable,
    FeeTable,
    ContractHtmlModal,
    ExtendHistoryTable,
    MediaView,
    ContractPreviewModal,
    PdfPreviewModal,
    BLink,
  },
  props: {
    contractId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Đã sao chép',
          icon: 'BellIcon',
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Không thể sao chép',
          icon: 'BellIcon',
        },
      });
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const userConfiguration = JSON.parse(localStorage.getItem('userConfiguration'));
    const enableSignContract = userConfiguration && userConfiguration.enableSigningContractOnline === true;
    const applyNewContractTemplate = userConfiguration && userConfiguration.applyNewContractTemplate === true;

    const {
      refModalDeleteCashbook,
      itemLocal,
      isTingTong,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      sendContractEmail,
      downloadContractPdf,
      downloadContractDocuments,
      onDeleteAttachment,
      sendContractSigningEmail,
      copySigningUrl,
      previewType,
      setPreviewType,

    } = useContractDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      isTingTong,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      previewType,
      parseDateToString,
      sendContractEmail,
      enableSignContract,
      downloadContractPdf,
      downloadContractDocuments,
      onDeleteAttachment,
      sendContractSigningEmail,
      copySigningUrl,
      setPreviewType,
      applyNewContractTemplate,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
