<template>
  <vue-good-table
    class="mt-2"
    :columns="columns"
    :rows="assets"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
  >
    <template slot="loadingContent">
      <b-spinner
        label="Loading"
        type="grow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: isRepresent -->
      <span v-if="props.column.field === 'isNormal'">
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="props.row.isNormal"
            :name="`isNormal-${props.row.originalIndex}`"
            :value="true"
            class="custom-control-primary"
            @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
          >
            Bình thường
          </b-form-radio>

          <b-form-radio
            v-model="props.row.isNormal"
            :name="`isNormal-${props.row.originalIndex}`"
            :value="false"
            class="custom-control-danger"
            @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
          >
            Không
          </b-form-radio>
        </div>
      </span>

      <!-- Column: Image -->
      <span v-else-if="props.column.field === 'note'">
        <b-form-input
          :id="`note-${props.row.originalIndex}`"
          v-model="props.row.note"
          placeholder=""
          trim
          @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
        />

      </span>

      <!-- Column: Image -->
      <span v-else-if="props.column.field === 'imageFile'">
        <image-view
          :id="`image-file-${props.row.originalIndex}`"
          v-model="props.row.imageFile"
          :src="props.row.image"
          :instruction="null"
          @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
          @onSelectFile="(base64String) => onSelectFile(base64String, props.row.originalIndex)"
        />

      </span>

      <span v-else-if="props.column.field === 'repairFee'">
        <number-input
          v-model="props.row.repairFee"
          :default-value="props.row.repairFee"
          @input="changeCell(props.row['repairFee'], 'repairFee', props.row.originalIndex)"
        />
        <b-form-input
          :id="`note-${props.row.originalIndex}`"
          v-model="props.row.note"
          placeholder="Ghi chú"
          trim
          @input="changeCell(props.row.note, 'note', props.row.originalIndex)"
        />
      </span>

      <span v-else-if="props.column.field === 'asset.name'">
        <div class="font-weight-bolder">
          {{ props.row.asset.name }}
        </div>
        <div>Sửa chữa: <strong>Bên thuê</strong></div>
        <div>Ghi chú: <strong>{{ props.row.oldNote }}</strong></div>
        <div>Hình ảnh khi nhận phòng:</div>
        <avatar-view
          :src="props.row.oldImage"
        />

      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <div slot="emptystate">
      <div class="text-center text-muted">
        Không có bản ghi nào!
      </div>
    </div>
  </vue-good-table>
</template>

<script>
import {
  BSpinner,
  BFormInput,
  BFormRadio,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import useJwt from '@/auth/jwt/useJwt';
import ImageView from '@/views/components/ImageView.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import AvatarView from '@/views/components/AvatarView.vue';

export default {
  components: {
    BSpinner,
    BFormInput,
    BFormRadio,
    VueGoodTable,
    ImageView,
    NumberInput,
    AvatarView,
  },
  props: {
    contractId: {
      type: Number,
      default: null,
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      assets: this.data,
    };
  },
  watch: {
    apartment(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },
    room(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },

  },
  created() {
    if (this.data.length === 0) {
      this.fetchData();
    }
  },
  methods: {
    changeCell(changedData, column, row) {
      this.assets[row][column] = changedData;
      this.onChange();
    },
    onChange() {
      this.$emit('input', this.assets);
    },
    fetchData() {
      if (!this.apartment || !this.room) {
        this.assets = [];
        return;
      }
      const filter = { };
      if (this.apartment && this.apartment.id > 0) {
        filter.apartmentId = this.apartment.id;
      }
      if (this.room && this.room.id > 0) {
        filter.roomId = this.room.id;
      }

      useJwt.getContractHandover(this.contractId).then(response => {
        this.assets = response.data.map(object => ({
          ...object,
          oldNote: object.note,
          oldImage: object.image,
          note: '',
          image: null,
        }));
        this.onChange();
      });

      // useJwt.getAssets({ filter }).then(response => {
      //   this.assets = response.data.items.map(object => ({
      //     asset: object,
      //     note: '',
      //     repairFee: 0,
      //     isNormal: true,
      //     base64String: null,
      //   }));
      // });
    },
    onDeleteAttachment() {},
    onSelectFile(value, index) {
      this.assets[index].base64String = value;
      this.onChange();
    },
  },
  setup() {
    // Table Handlers
    const rows = [];
    const columns = [
      {
        label: 'Tài sản',
        field: 'asset.name',
        sortable: false,
      },
      {
        label: 'Tình trạng hoạt động',
        field: 'isNormal',
        sortable: false,
        width: '200px',
      },
      {
        label: 'Phí sửa chữa',
        field: 'repairFee',
        sortable: false,
        width: '200px',
      },
      {
        label: 'Hình ảnh',
        field: 'imageFile',
        sortable: false,
        width: '150px',
      },
    ];

    return {
      rows,
      columns,
    };
  },
};
</script>
