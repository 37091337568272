<template>
  <b-modal
    id="modal-contract-update-time"
    ref="refModalContractUpdateTime"
    :title="itemLocal.isExtend === true ? 'Gia hạn hợp đồng' : 'Đăng ký ngày chuyển đi'"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-alert
        show
        variant="primary"
      >
        <div class="alert-body">
          Hợp đồng hiện tại có ngày hết hạn là: <strong>{{ parseDateToString(itemLocal.endDate) }}. </strong> <br>
          <span v-if="itemLocal.newEndDate && itemLocal.isExtend">Sau khi gia hạn, hợp đồng có ngày hết hạn là: <strong>{{ parseDateToString(itemLocal.newEndDate) }}</strong> </span>
          <span v-if="itemLocal.isExtend"><br>Giá mới của căn hộ là: <strong>{{ Number(itemLocal.room.price).toLocaleString() }} đ</strong>, giá của hợp đồng là: <strong>{{ Number(itemLocal.price).toLocaleString() }} đ</strong> </span>

        </div>
      </b-alert>
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row v-if="itemLocal.isExtend">
          <b-col

            cols="12"
          >
            <validation-provider

              #default="validationContext"
              name="extendMonth"
              rules="required"
            >

              <select-extend-month
                v-if="itemLocal.isExtend"
                v-model="itemLocal.extendMonth"
                required
                label="Số tháng gia hạn"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>

          </b-col>
          <b-col
            v-if="!isTingTong && itemLocal.extendMonth && itemLocal.extendMonth.id === 0"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="newEndDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.newEndDate"
                required
                :default-value="itemLocal.newEndDate"
                label="Hạn hợp đồng mới"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              /></validation-provider>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col

            cols="12"
          >
            <select-date
              v-model="itemLocal.leavingDate"
              :default-value="itemLocal.leavingDate"
              :label="'Ngày sẽ chuyển đi'"
            />
          </b-col>
        </b-row>

        <b-row v-if="itemLocal.isExtend && isTingTong">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Tiền thuê mới"
              rules="required"
            >
              <number-input
                v-model="itemLocal.price"
                required
                label="Tiền thuê mới"
                :default-value="itemLocal.price"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col v-if="!isTingTong">
            <validation-provider
              #default="validationContext"
              name="deposit"
              rules="required"
            >
              <select-deposit-month
                v-model="itemLocal.depositMonthObject"
                :default-value="itemLocal.depositMonthObject"
                label="Tiền cọc"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="itemLocal.isExtend && isTingTong">
          <b-col>
            <validation-provider
              #default="validationContext"
              name="discount"
              rules="required"
            >
              <number-input
                v-model="itemLocal.promotionMonth"
                required
                label="Số tháng giảm"
                :default-value="itemLocal.promotionMonth"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Số tiền giảm mỗi tháng"
              rules="required"
            >
              <number-input
                v-model="itemLocal.promotionPricePerMonth"
                required
                label="Số tiền giảm mỗi tháng"
                :default-value="itemLocal.promotionPricePerMonth"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import { parseDateToString } from '@/auth/utils';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDepositMonth from '@/views/components/SelectDepositMonth.vue';
import SelectExtendMonth from '@/views/components/SelectExtendMonth.vue';
import useContractUpdateTimeModal from './useContractUpdateTimeModal';

export default {
  components: {
    BModal,
    SelectDate,
    BAlert,
    BRow,
    BCol,

    BForm,
    ValidationObserver,
    ValidationProvider,
    NumberInput,
    SelectDepositMonth,
    SelectExtendMonth,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'extend',
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalContractUpdateTime,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      isTingTong,
      onSubmit,
    } = useContractUpdateTimeModal(props, emit, refFormObserver);

    return {
      refModalContractUpdateTime,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      isTingTong,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      parseDateToString,
    };
  },
};
</script>
