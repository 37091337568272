<template>
  <b-modal
    id="modal-contract-liquidation"
    ref="refModalLiquidation"
    title="Thanh lý hợp đồng"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lập hóa đơn & Thanh lý'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="openModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>
    <!-- Body -->
    <validation-observer
      v-if="contract && !isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Thông tin hợp đồng
              </div>
            </div>
          </b-col>
        </b-row>
        <b-list-group
          class="pb-1"
        >
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Đại diện:
              </b-col>
              <b-col class="text-right">
                {{ contract.name }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Ngày khách vào:
              </b-col>
              <b-col class=" text-right">
                {{ parseDateToString(contract.startDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Hạn hợp đồng:
              </b-col>
              <b-col class="text-right">
                {{ parseDateToString(contract.endDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Tiền đặt cọc:
              </b-col>
              <b-col class="text-right">
                {{ Number(contract.deposit).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="contract.leavingDate">
            <b-row>
              <b-col class="font-weight-bolder">
                Ngày đăng ký chuyển:
              </b-col>
              <b-col class="text-right">
                {{ itemLocal.leavingDate }}
              </b-col>
            </b-row>
          </b-list-group-item>

        </b-list-group>

        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="liquidDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.liquidDate"
                required
                :default-value="itemLocal.liquidDate"
                label="Ngày chuyển đi"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                @on-date-change="onLiquidDateChange(itemLocal.liquidDate)"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Công nợ khách hàng
              </div>
            </div>
          </b-col>
        </b-row>
        <invoice-table
          v-if="itemLocal.invoices.length > 0"
          :data="itemLocal.invoices"
        />
        <b-alert
          v-else
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">Khách hàng không còn nợ khoản tiền nào.</span>
          </div>
        </b-alert>
        <b-row v-if="isEnableContractAssetCheck()">
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                3. Bàn giao tài sản
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="isEnableContractAssetCheck()">
          <b-col cols="12">
            <handover-liquid-table
              v-model="itemLocal.liquidHandover"
              :apartment="contract.apartment"
              :room="contract.room"
              :contract-id="contract.id"
            />
          </b-col>
          <b-col cols="12">
            <other-handover-liquid-table
              v-model="itemLocal.liquidOtherHandover"
              :contract-id="contract.id"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                {{ isEnableContractAssetCheck() ? '4' : '3' }}. Hoàn cọc và tiền thừa

              </div>
            </div>
          </b-col>
        </b-row>
        <b-alert
          v-if="itemLocal.maxPrepaid > 0"
          show
          variant="danger"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">Số tiền thừa đang giữ của khách là {{ Number(itemLocal.maxPrepaid).toLocaleString() }}. (<b-link v-b-modal.prepaid-detail-modal>Xem chi tiết</b-link>)</span>
          </div>
        </b-alert>
        <b-row>
          <b-col cols="4">
            <validation-provider
              #default="validationContext"
              name="Hoàn trả cọc"
              :rules="`required|maxValue:${contract.deposit}`"
            >
              <number-input
                v-model="itemLocal.deposit"
                required
                label="Hoàn trả cọc?"
                :default-value="itemLocal.deposit"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              #default="validationContext"
              name="Hoàn trả tiền thừa"
              :rules="`required|maxValue:${itemLocal.maxPrepaid}`"
            >
              <number-input
                v-model="itemLocal.prepaid"
                required
                label="Hoàn trả tiền thừa?"
                :default-value="itemLocal.prepaid"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :disabled="itemLocal.maxPrepaid <= 0"
              />
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              #default="validationContext"
              name="Phí phạt"
              rules=""
            >
              <number-input
                v-model="itemLocal.penaltyFee"
                label="Phí phạt"
                :default-value="itemLocal.penaltyFee"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              :name="itemLocal.refundRoomEndDate ? `Trả lại tiền phòng từ ngày ${parseDateToString(returnRoomStartDate)} đến ngày ${parseDateToString(itemLocal.refundRoomEndDate)}` : 'Trả lại tiền phòng'"
              :rules="`required`"
            >
              <number-input
                v-model="itemLocal.refundRoomAmount"
                required
                :label="itemLocal.refundRoomEndDate ? `Trả lại tiền phòng từ ngày ${parseDateToString(returnRoomStartDate)} đến ngày ${parseDateToString(itemLocal.refundRoomEndDate)}` : 'Trả lại tiền phòng'"
                :default-value="itemLocal.refundRoomAmount"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :disabled="false"
              />
            </validation-provider>
          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                {{ isEnableContractAssetCheck() ? '5' : '4' }}. Tổng hợp
              </div>
            </div>
          </b-col>
        </b-row>

        <b-list-group
          class="pb-1"
        >
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Tổng tiền khách nợ: (1)
              </b-col>
              <b-col class="text-right">
                {{ Number(totalDebt).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Tổng phí phạt: (2)
              </b-col>
              <b-col class="text-right">
                {{ Number(totalRepairFee).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Hoàn cọc: (3)
              </b-col>
              <b-col class=" text-right">
                {{ Number(itemLocal.deposit).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Hoàn trả tiền thừa : (4)
              </b-col>
              <b-col class="text-right">
                {{ Number(itemLocal.prepaid).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Trả lại tiền phòng : (5)
              </b-col>
              <b-col class="text-right">
                {{ Number(itemLocal.refundRoomAmount).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                Tổng cộng: (6) = (1) + (2) - (3) - (4) - (5)
              </b-col>
              <b-col class="text-right">
                {{ remain.toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="remain != 0">
            <span
              v-if="remain > 0"
              class="text-primary"
            >{{ `Khách hàng cần thanh toán cho bạn ${remain.toLocaleString()}` }} đ</span>
            <span
              v-else
              class="text-danger"
            >{{ `Bạn cần thanh toán cho khách hàng ${(remain * -1).toLocaleString()}` }} đ</span>

          </b-list-group-item>

        </b-list-group>
      </b-form>
    </validation-observer>

    <prepaid-detail :item="{contract: contract, contractId: contract.id}" />
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
  BListGroup,
  BListGroupItem,
  VBTooltip,
  BLink,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, maxValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import PrepaidDetail from '@/views/finance/prepaid/detail/PrepaidDetail.vue';
import { parseDateToString } from '@/auth/utils';
import InvoiceTable from './InvoiceTable.vue';
import useContractLiquidation from './useContractLiquidation';
import HandoverLiquidTable from './HandoverLiquidTable.vue';
import OtherHandoverLiquidTable from './OtherHandoverLiquidTable.vue';

export default {
  components: {
    BModal,
    BAlert,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BLink,
    SelectDate,
    InvoiceTable,
    NumberInput,
    BListGroup,
    BListGroupItem,
    PrepaidDetail,
    BSpinner,
    HandoverLiquidTable,
    OtherHandoverLiquidTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalLiquidation,
      itemLocal,
      totalDebt,
      total,
      contract,
      totalRepairFee,
      remain,
      isSubmitting,
      isLoading,
      isTingTong,
      returnRoomStartDate,
      resetItemLocal,
      resetModal,
      onSubmit,
      openModal,
      isEnableContractAssetCheck,
      onLiquidDateChange,
    } = useContractLiquidation(props, emit, refFormObserver);

    return {
      refModalLiquidation,
      itemLocal,
      totalDebt,
      total,
      totalRepairFee,
      contract,
      remain,
      isSubmitting,
      isLoading,
      isTingTong,
      returnRoomStartDate,
      resetItemLocal,
      resetModal,
      onSubmit,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      openModal,
      parseDateToString,
      isEnableContractAssetCheck,
      onLiquidDateChange,
    };
  },
};
</script>
