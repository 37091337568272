import {
  computed,
  ref,
  watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';

export default function useContractHTMLModal(props, emit, refFormObserver) {
  const toastification = toast();
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const contractId = computed(() => props.contractId);

  const getContractHTML = () => {
    if (contractId.value) {
      isLoading.value = true;
      store.dispatch('contract/getContractHTML', contractId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const printContract = () => {
    if (itemLocal.value) {
      const w = window.open();
      w.document.write(itemLocal.value);
      w.document.close();
      w.setTimeout(() => {
        w.print();
      }, 1000);
    }
  };

  const onOpen = () => {
    resetModal();
    getContractHTML();
  };

  watch(contractId, val => {

  });

  return {
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onOpen,
    isLoading,
    printContract,
  };
}
